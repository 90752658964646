<!-- 批量导入 -->
<template>
    <div class="BatchImport">
        <div class="" style="margin: 25px;font-size: 16px;font-weight:bold;">
            批量导入
        </div>
        <!-- 功能按钮 -->
        <div class="" style="margin-left:22px ;display: flex;">
            <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
            <!-- <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;" @click="newlyAdded">
                新增
            </el-button> -->
            <el-upload ref="upload" 
                action="https://artxt.szart.cn/api/public/index.php/api/mechanismexam/upload" 
                :limit="1" :before-upload="beforeUpload" :data = "importData"   
                accept=".xls,.xlsx" class="up-class" :on-success="uploadSuccess" :show-file-list="false"
                    :auto-upload="true" >
                    <el-button slot="trigger" size="small"  class="daoru"> 
                        批量导入
                    </el-button>
                </el-upload>
                <el-button size="small"  class="down" icon="el-icon-download" @click='download'>
                    下载导入模板
                </el-button>
        </div>
        
        <!-- 弹出提示 -->
        <el-dialog title="提示" :visible.sync="dialog" width="40%" :before-close="handleClose">
            <span>正在导入中，请稍等。。。</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialog = false">取 消</el-button>
                <el-button style="background:#409EFF ;color: #fff;" @click="dialog = false">确 定</el-button>
            </span>
        </el-dialog>
        
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650">
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column prop="" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="" label="姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="拼音名" align="center" width="200">
            </el-table-column>
            <el-table-column prop="" label="性别" align="center" width="100">
            </el-table-column>
            <el-table-column prop="" label="出生日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" label="国籍" align="center" width="100">
            </el-table-column>
            <el-table-column prop="" label="民族" align="center" width="100">
            </el-table-column>
            <el-table-column prop="" label="报考学科" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" label="原等级" align="center" width="100">
            </el-table-column>
            <el-table-column prop="" label="报考等级" align="center" width="100">
            </el-table-column>
            <el-table-column prop="" label="移动电话" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" label="证件号" align="center" width="200">
            </el-table-column>
            <el-table-column prop="" label="是否有副证" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="操作" align="center" fixed="right" width="200">
                <template slot-scope="scope">
                    <el-button icon="el-icon-edit" size="mini" style="background:#18BC9C;color: #fff;"
                    @click="edit(scope.$index, scope.row)">
                        编辑
                    </el-button>
                    <el-button  icon="el-icon-delete" size="mini" style="background:#E74C3C;color: #fff;"
                    @click="del(scope.$index, scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" 
        @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: [],
                currentPage: 1,
                total: 0,
                importData:{
                    uid:localStorage.getItem('token'),
                },
                dialog: false //用于判断弹窗
            }
        },
        created() {

        },
        methods: {
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            },
            // 刷新
            refresh() {
                this.$router.replace({
                    path: "/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                this.$router.replace({
                    path: "/addBatchImport"
                })
            },
            edit(index, row) {
                this.$router.replace({
                    path: "/editBatchImport",
                    query: {
                        path: row.id
                    }
                })
            },
            del(index,row){//删除
                
            },
            
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            
            // 导入
            beforeUpload(file) {
                // console.log(file)
                let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
                const isXLS = extension === 'xls';
                const isXLSX = extension === 'xlsx';
                let flag = [isXLS, isXLSX].includes(true)
                
                if (!flag) {
                    this.$message({
                        message: '上传文件只能是 xls，xlsx格式!',
                        type: 'warning'
                    });
                    return false;
                }
                this.dialog = true
            },
            uploadSuccess(res, file, fileList) {
                this.dialog = false
                if (res.code == 200) {
                    this.$message({
                        message: '导入成功!',
                        type: 'success'
                    });
                } else {
                    this.$alert(res.msg, '导入失败', {
                        confirmButtonText: '确定',
                    });
                }
                this.$refs.upload.clearFiles();
            },
            
            download(){//下载导入模板
                window.open('https://artxt.szart.cn/TransDataFor710ZT.xls')
            }
        }
    }
</script>

<style scoped="scoped">
    .BatchImport {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /* 查询部分 */
    .form {
        padding-top: 34px;
        display: flex;
        /* justify-content: space-between;
        flex-wrap: wrap; */
    }

    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }

    /* 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }

    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }
    ::v-deep .el-icon-download {
        font-weight: bold !important;
    }
    /* 导入按钮 */
    .daoru {
        margin-left: 10px;
        padding-left: 30px;
        background: url(../../assets/import.png) 10px 6px no-repeat #FF7300;
        color: #FFFFFF;
    }
    /* 下载导入模板按钮 */
    .down{
        margin-left: 10px;
        color: #FFFFFF;
        background: #FF7300;
    }
    
    /* 表格 */
    .el-table {
        margin: 20px;
    }

    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 35px;
    }

    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
